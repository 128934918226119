body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: none;
}
body::-webkit-scrollbar {
  width: 12px;  /* Remove scrollbar space */
 /* background: transparent;   Optional: just make scrollbar invisible */
}
body::-webkit-scrollbar-track {
  background: #f1f1f1;  /* Set a background color for the scrollbar track */
}
body::-webkit-scrollbar-track {
  background: #f1f1f1;  /* Set a background color for the scrollbar track */
}

body::-webkit-scrollbar-thumb {
  background: #888;  /* Set a color for the scrollbar thumb */
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;  /* Set a color for the scrollbar thumb on hover */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  margin-top: 40px;
}

.calendar-cell {
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.form-outline{
  max-width: 500px;
  align-self: center;
  margin-left: 20px;
}
.form-control{
  border-color: black;
}
.col-12{
  /* border: 3px solid; */
  border-color: black;
  margin-bottom: 20px;
}
.col-6{
  /* border: 3px solid; */
  border-color: black;
  margin-bottom: 20px;
}

th,td{
  padding: 10px;
  border: solid 1px black;
}
table{
  margin: auto;
}
#disp{
  display:none;
}
.row{
  margin: 20px;
}